import { motion } from "framer-motion";

interface DualRangeSliderProps {
  minValue: number;
  maxValue: number;
  step: number;
  currentMin: number;
  currentMax: number;
  formatValue?: (value: number) => string;
  onChange: (min: number, max: number) => void;
}

const DualRangeSlider = ({
  minValue,
  maxValue,
  step,
  currentMin,
  currentMax,
  formatValue = (value) => value.toString(),
  onChange,
}: DualRangeSliderProps) => {
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMin = Math.min(Number(e.target.value), currentMax);
    onChange(newMin, currentMax);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMax = Math.max(Number(e.target.value), currentMin);
    onChange(currentMin, newMax);
  };

  return (
    <div className="relative h-12 w-full">
      <div className="absolute top-0 left-0 right-0">
        <div className="relative h-2">
          {/* Track background */}
          <div className="absolute h-[2px] w-full bg-stone-300 top-1/2 -translate-y-1/2" />

          {/* Selected range */}
          <motion.div
            initial={false}
            className="absolute h-[2px] bg-stone-600 top-1/2 -translate-y-1/2"
            style={{
              left: `${((currentMin - minValue) / (maxValue - minValue)) * 100}%`,
              right: `${
                100 - ((currentMax - minValue) / (maxValue - minValue)) * 100
              }%`,
            }}
          />

          {/* Min range input */}
          <input
            type="range"
            min={minValue}
            max={maxValue}
            value={currentMin}
            step={step}
            onChange={handleMinChange}
            className="absolute w-full top-1/2 -translate-y-1/2 appearance-none bg-transparent pointer-events-none [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-stone-800 [&::-webkit-slider-thumb]:pointer-events-auto [&::-webkit-slider-thumb]:cursor-pointer [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:h-4 [&::-moz-range-thumb]:w-4 [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:bg-stone-800 [&::-moz-range-thumb]:pointer-events-auto [&::-moz-range-thumb]:cursor-pointer [&::-moz-range-thumb]:border-0"
          />

          {/* Max range input */}
          <input
            type="range"
            min={minValue}
            max={maxValue}
            value={currentMax}
            step={step}
            onChange={handleMaxChange}
            className="absolute w-full top-1/2 -translate-y-1/2 appearance-none bg-transparent pointer-events-none [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-stone-800 [&::-webkit-slider-thumb]:pointer-events-auto [&::-webkit-slider-thumb]:cursor-pointer [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:h-4 [&::-moz-range-thumb]:w-4 [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:bg-stone-800 [&::-moz-range-thumb]:pointer-events-auto [&::-moz-range-thumb]:cursor-pointer [&::-moz-range-thumb]:border-0"
          />
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0 flex justify-between text-sm text-stone-600 font-serif">
        <span>{formatValue(currentMin)}</span>
        <span>{formatValue(currentMax)}</span>
      </div>
    </div>
  );
};

export default DualRangeSlider;
