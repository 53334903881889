import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const ListingCard = ({
  images,
  title,
  location,
  price,
}: {
  images: string[];
  title: string;
  location: string;
  price: string;
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const { t } = useTranslation();

  const nextImage = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const previousImage = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="group"
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <Link to={`/property/${encodeURIComponent(title)}`}>
        <div className="relative">
          <div className="absolute -inset-2 border border-stone-300/40" />
          <div className="relative overflow-hidden">
            <AnimatePresence mode="wait">
              <motion.img
                key={currentImageIndex}
                src={images[currentImageIndex]}
                alt={title}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="w-full h-[400px] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98] transition-all duration-500 group-hover:scale-105"
              />
            </AnimatePresence>
            <div className="absolute inset-0 bg-stone-900/10" />

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: showControls ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              className="absolute inset-0 flex items-center justify-between px-4"
            >
              <button
                onClick={previousImage}
                className="w-10 h-10 rounded-full bg-stone-900/50 text-stone-100 flex items-center justify-center hover:bg-stone-900/70 transition-all"
              >
                <FiChevronLeft size={24} />
              </button>
              <button
                onClick={nextImage}
                className="w-10 h-10 rounded-full bg-stone-900/50 text-stone-100 flex items-center justify-center hover:bg-stone-900/70 transition-all"
              >
                <FiChevronRight size={24} />
              </button>
            </motion.div>

            <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-stone-900/80 to-transparent">
              <div className="text-stone-100 font-serif">
                <h3 className="text-xl mb-2">
                  {title === "T3 rénové au Lac"
                    ? t("property.properties.t3Title")
                    : title === "Appartement neuf avec jardin"
                      ? t("property.properties.newApartmentWithGarden")
                      : title}
                </h3>
                <p className="text-stone-300 text-sm tracking-wider">
                  {location}
                </p>
                <p className="text-stone-100 text-sm tracking-wider mt-2">
                  {price}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

const Listings = () => {
  const { t } = useTranslation();
  const listings = [
    {
      images: [
        "https://createsiteai.b-cdn.net/c/_/270bb3a5-81b7-4099-826f-6f6e90bd47fe",
        "https://createsiteai.b-cdn.net/c/_/48584988-3e31-4869-bbb8-a30b1395ff44",
        "https://createsiteai.b-cdn.net/c/_/818d1030-54ae-4a80-9e2e-af55545135d9",
        "https://createsiteai.b-cdn.net/c/_/4935fc4c-201a-4425-a70b-43b631b25f8c",
        "https://createsiteai.b-cdn.net/c/_/d7446ce0-7207-4de9-a1e4-3c53d5a8f94c",
      ],
      title: "T3 rénové au Lac",
      location: "Saint-Julien-du-Verdon, France",
      price: "280 000€",
    },
    {
      images: [
        "https://createsiteai.b-cdn.net/c/_/a32fb517-80be-4edd-8cd7-c8e24f9e18d9",
        "https://createsiteai.b-cdn.net/c/_/b8c6fd0b-9cf0-4314-ac13-b70f47cfbfe2",
        "https://createsiteai.b-cdn.net/c/_/b6f8a884-309f-4599-9560-0287e94a7886",
      ],
      title: "Appartement neuf avec jardin",
      location: "Forcalquier, France",
      price: "430 000€",
    },
  ];

  return (
    <div className="w-full bg-[#f8f5f2] desktop:py-24 laptop:py-24 tablet:py-20 mobile:py-8">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <div className="flex items-center gap-4 mb-8">
            <div className="w-12 h-[1px] bg-stone-400" />
            <span className="text-stone-500 font-serif text-sm tracking-[0.3em]">
              {t("listings.tagline")}
            </span>
          </div>
          <h2 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-stone-800 leading-[1.1]">
            {t("listings.title")}
          </h2>
        </motion.div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
          {listings.map((listing, index) => (
            <ListingCard key={index} {...listing} />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mt-16 text-center"
        >
          <Link
            to="/collection"
            className="group relative inline-block px-12 py-5 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden"
          >
            <span className="relative z-10">{t("listings.cta")}</span>
            <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Listings;
