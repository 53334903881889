import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";

interface Property {
  image: string;
  title: string;
  location: string;
  price: string;
}

interface SimilarPropertiesProps {
  currentLocation: string;
  currentTitle: string;
}

const SimilarProperties = ({
  currentLocation,
  currentTitle,
}: SimilarPropertiesProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePropertyClick = (propertyTitle: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/property/${encodeURIComponent(propertyTitle)}`);
  };

  // Using the properties from the collection page
  const collectionProperties: Property[] = [
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/a32fb517-80be-4edd-8cd7-c8e24f9e18d9",
      title: "Appartement neuf avec jardin",
      location: "Forcalquier, France",
      price: "430 000€",
    },
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/270bb3a5-81b7-4099-826f-6f6e90bd47fe",
      title: "T3 rénové au Lac",
      location: "Saint-Julien-du-Verdon, France",
      price: "280 000€",
    },
  ].filter((prop) => prop.title !== currentTitle);

  return (
    <div className="mt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="mb-12"
      >
        <div className="flex items-center gap-4 mb-8">
          <div className="w-12 h-[1px] bg-stone-400" />
          <span className="text-stone-500 font-serif text-sm tracking-[0.3em]">
            {t("property.similarProperties")}
          </span>
        </div>
      </motion.div>

      <Swiper
        modules={[Navigation]}
        navigation
        spaceBetween={32}
        breakpoints={{
          0: { slidesPerView: 1 },
          767: { slidesPerView: 2 },
          1023: { slidesPerView: 3 },
        }}
        className="similar-properties-swiper"
      >
        {collectionProperties.map((property, index) => (
          <SwiperSlide key={index}>
            <div onClick={() => handlePropertyClick(property.title)}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                className="group cursor-pointer"
              >
                <div className="relative">
                  <div className="absolute -inset-2 border border-stone-300/40" />
                  <div className="relative overflow-hidden">
                    <img
                      src={property.image}
                      alt={property.title}
                      className="w-full h-[300px] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98] transition-all duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-stone-900/10" />
                    <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-stone-900/80 to-transparent">
                      <div className="text-stone-100 font-serif">
                        <h3 className="text-xl mb-2">
                          {property.title === "T3 rénové au Lac"
                            ? t("property.properties.t3Title")
                            : property.title === "Appartement neuf avec jardin"
                              ? t("property.properties.newApartmentWithGarden")
                              : property.title}
                        </h3>
                        <p className="text-stone-300 text-sm tracking-wider">
                          {property.location}
                        </p>
                        <p className="text-stone-100 text-sm tracking-wider mt-2">
                          {property.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SimilarProperties;
