import { Outlet } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";

const Layout = () => {
  return (
    <>
      <Outlet />
      <LanguageSelector />
    </>
  );
};

export default Layout;
