import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { FiChevronLeft, FiChevronRight, FiX } from "react-icons/fi";

interface ImageLightboxProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  currentImageIndex: number;
  onPrevious: () => void;
  onNext: () => void;
  onThumbnailClick: (index: number) => void;
}

const ImageLightbox = ({
  isOpen,
  onClose,
  images,
  currentImageIndex,
  onPrevious,
  onNext,
  onThumbnailClick,
}: ImageLightboxProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          static
          open={isOpen}
          onClose={onClose}
          className="fixed inset-0 z-50 flex items-center justify-center"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-stone-900/90"
          />

          <Dialog.Panel className="relative w-full h-full flex flex-col items-center justify-center px-4">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-stone-100 hover:text-stone-300 transition-colors z-50"
            >
              <FiX size={24} />
            </button>

            <div className="relative w-full max-w-[1800px] flex items-center justify-center">
              <button
                onClick={onPrevious}
                className="absolute left-4 z-50 w-10 h-10 rounded-full bg-stone-900/50 text-stone-100 flex items-center justify-center hover:bg-stone-900/70 transition-all"
              >
                <FiChevronLeft size={24} />
              </button>

              <div className="relative w-full flex items-center justify-center">
                <AnimatePresence mode="wait" initial={false}>
                  <motion.img
                    key={currentImageIndex}
                    initial={{ opacity: 0, scale: 0.98 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.98 }}
                    transition={{
                      duration: 0.15,
                      ease: "easeOut",
                    }}
                    src={images[currentImageIndex]}
                    alt={`Image ${currentImageIndex + 1}`}
                    className="max-h-[80vh] w-auto object-contain"
                  />
                </AnimatePresence>
              </div>

              <button
                onClick={onNext}
                className="absolute right-4 z-50 w-10 h-10 rounded-full bg-stone-900/50 text-stone-100 flex items-center justify-center hover:bg-stone-900/70 transition-all"
              >
                <FiChevronRight size={24} />
              </button>
            </div>

            <div className="absolute bottom-8 left-0 right-0">
              <div className="max-w-[1800px] mx-auto px-4">
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  className="flex gap-4 overflow-x-auto py-4 scrollbar-hide"
                >
                  {images.map((image, index) => (
                    <motion.button
                      key={index}
                      onClick={() => onThumbnailClick(index)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 0.15 }}
                      className={`flex-shrink-0 relative ${
                        index === currentImageIndex
                          ? "ring-2 ring-stone-100"
                          : "opacity-50 hover:opacity-100 transition-opacity"
                      }`}
                    >
                      <img
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className="w-24 h-16 object-cover"
                      />
                    </motion.button>
                  ))}
                </motion.div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export default ImageLightbox;
