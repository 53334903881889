import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const PolitiqueConfidentialite = () => {
  return (
    <div className="w-full bg-[#f8f5f2] min-h-screen py-24">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-12"
        >
          <div className="flex items-center gap-4">
            <Link
              to="/"
              className="text-stone-500 font-serif text-sm tracking-wider hover:text-stone-800 transition-colors flex items-center gap-2"
            >
              <span className="w-12 h-[1px] bg-stone-400" />
              <span>RETOUR À L'ACCUEIL</span>
            </Link>
          </div>

          <h1 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-stone-800 leading-[1.1]">
            Politique de Confidentialité
          </h1>

          <div className="prose prose-stone max-w-none font-serif">
            <p>
              Date de dernière mise à jour : {new Date().toLocaleDateString()}
            </p>

            <h2>1. Collecte des données</h2>
            <p>
              Nous collectons les informations que vous nous fournissez
              directement lors de l'utilisation de nos services.
            </p>

            <h2>2. Utilisation des données</h2>
            <p>Les données collectées sont utilisées pour :</p>
            <ul>
              <li>Vous fournir nos services immobiliers</li>
              <li>Personnaliser votre expérience</li>
              <li>Communiquer avec vous</li>
              <li>Améliorer nos services</li>
            </ul>

            <h2>3. Protection des données</h2>
            <p>
              Nous mettons en œuvre des mesures de sécurité appropriées pour
              protéger vos données personnelles.
            </p>

            <h2>4. Vos droits</h2>
            <p>Conformément au RGPD, vous disposez des droits suivants :</p>
            <ul>
              <li>Droit d'accès</li>
              <li>Droit de rectification</li>
              <li>Droit à l'effacement</li>
              <li>Droit à la limitation du traitement</li>
            </ul>

            <h2>5. Contact</h2>
            <p>
              Pour toute question concernant cette politique, contactez-nous à :
              contact@vivaldy.fr
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialite;
