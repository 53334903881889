import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { propertyImages } from "../config/propertyImages";
import { useParams, Link } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import DPEDropdown from "../components/DPEDropdown";
import SimilarProperties from "../components/SimilarProperties";
import ImageLightbox from "../components/ImageLightbox";
import PropertyMap from "../components/PropertyMap";

const PropertyDetail = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const decodedId = decodeURIComponent(id || "");
  const propertyData = propertyImages[decodedId];

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const getTranslatedTitle = (originalTitle: string) => {
    if (originalTitle === "Appartement neuf avec jardin") {
      return t("property.properties.newApartmentWithGarden");
    }
    return originalTitle;
  };

  const property = {
    title:
      decodedId === "T3 rénové au Lac"
        ? t("property.properties.t3Title")
        : getTranslatedTitle(decodedId),
    location:
      decodedId === "Appartement neuf avec jardin"
        ? "Forcalquier, France"
        : "Saint-Julien-du-Verdon, France",
    price:
      decodedId === "Appartement neuf avec jardin" ? "430 000€" : "280 000€",
    description:
      decodedId === "T3 rénové au Lac"
        ? t("property.properties.t3Description")
        : decodedId === "Appartement neuf avec jardin"
          ? t("property.properties.newApartmentDescription")
          : "Découvrez Saint-Julien-du-Verdon, à seulement 2h de Nice, Marseille et Toulon, ce havre de paix avec sa vue dominante sur le lac de Castillon, est un des plus beaux villages du Verdon. \n\n\nEncore méconnu du grand public, Saint-Julien-du-Verdon bénéficie des avantages apaisants du Verdon, sans les contraintes du surtourisme des autres lieux de cette région. De nombreux restaurants et commerces de qualité se situent à moins de 10min en voiture (à Vergons, Castellane, Saint-Andre-les-Alpes, ou encore Annot), et nous serions ravis de vous donner nos plus belles adresses.  \n\n\nSi vous cherchez un lieu calme et magnifique pour passer vos vacances, vous êtes au bon endroit. Un parking municipal est disponible pour les résidents à quelques mètres de l'immeuble.\n\n\nCet appartement traversant est situé au R+2, dans une maison de village que nous venons tout juste de rénover avec des matériaux de qualité.  \n\n\nCe bien est vendu meublé et décoré, allant de la table en noyer jusqu'au lave vaisselle BOB Made in France.     Radiateurs à induction, double vitrage, isolation par l'intérieur… vous disposerez de tout le confort moderne dans le charme de l'ancien.     Le + de cet appartement est son parquet en bois massif.     La surface totale au sol est de 78,10 m² (67,25 m² loi Carrez), et est composée : - d'une charmante cuisine entièrement équipée (10,25 m²) - d'un grand séjour avec velux, bureau, canapé, armoire, et table ronde (24,35 m²) - d'une salle d'eau avec WC (4,70 m²) - de deux chambres (14,55 m² & 13,20 m²) - d'un joli palier, meublé d'une pendule faite à Castellane au XXème siècle",
    features:
      decodedId === "Appartement neuf avec jardin"
        ? [
            t("property.properties.features.surface") + "109 m²",
            t("property.properties.features.bedrooms") + "2",
            t("property.properties.features.bathrooms") + "1",
            "Jardin 79 m²",
            "Terrasse 42 m²",
            "Garage double en option (28 000€)",
          ]
        : [
            t("property.properties.features.surface") + "78 m²",
            t("property.properties.features.bedrooms") + "2",
            t("property.properties.features.bathrooms") + "1",
            t("property.properties.features.lakeDist"),
            t("property.properties.features.renovated"),
            t("property.properties.features.topFloor"),
          ],
    mainImage:
      propertyData?.mainImage || propertyImages["Villa Provençale"].mainImage,
    detailImages:
      propertyData?.detailImages ||
      propertyImages["Villa Provençale"].detailImages,
  };

  const allImages = [property.mainImage, ...property.detailImages];

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (prev) => (prev - 1 + allImages.length) % allImages.length,
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % allImages.length);
  };

  const city = property.location.split(",")[0].trim();

  return (
    <div className="w-full bg-[#f8f5f2] min-h-screen py-24">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-12"
        >
          <div className="flex items-center gap-4">
            <Link
              to="/collection"
              className="text-stone-500 font-serif text-sm tracking-wider hover:text-stone-800 transition-colors"
            >
              ← {t("property.backToCollection")}
            </Link>
          </div>

          <div className="relative">
            <div className="absolute -inset-4 border border-stone-300/40" />
            <div
              className="relative overflow-hidden cursor-pointer"
              onClick={() => {
                setCurrentImageIndex(0);
                setIsLightboxOpen(true);
              }}
            >
              <img
                src={property.mainImage}
                alt={property.title}
                className="w-full desktop:h-[70vh] laptop:h-[70vh] tablet:h-[60vh] mobile:h-[45vh] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98]"
              />
              <div className="absolute inset-0 bg-stone-900/10" />
            </div>
          </div>

          <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
            <div className="space-y-6">
              <h1 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-stone-800 leading-[1.1]">
                {property.title}
              </h1>
              <p className="text-stone-600 font-serif text-lg whitespace-pre-line">
                {property.description}
              </p>
              <div className="space-y-2">
                <h2 className="font-serif text-xl text-stone-800 mb-4">
                  {t("property.features")}
                </h2>
                {property.features.map((feature, index) => (
                  <p key={index} className="text-stone-600 font-serif">
                    {feature}
                  </p>
                ))}
              </div>
            </div>

            <div className="space-y-6">
              <DPEDropdown />
              <div className="border-t border-stone-300 pt-6">
                <p className="font-serif text-2xl text-stone-800">
                  {property.price}
                </p>
                <p className="text-stone-600 font-serif">{property.location}</p>
              </div>
              <a
                href="tel:0644017168"
                className="desktop:w-64 desktop:mx-0 laptop:w-64 laptop:mx-0 tablet:w-64 tablet:mx-auto mobile:w-64 mobile:mx-auto group relative px-12 py-5 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden flex items-center justify-center gap-2"
              >
                <FiPhone className="relative z-10" />
                <span className="relative z-10">
                  {t("property.properties.phone")}
                </span>
                <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
              </a>
            </div>
          </div>

          <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
            {property.detailImages.map((image, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                onClick={() => {
                  setCurrentImageIndex(index + 1);
                  setIsLightboxOpen(true);
                }}
                className="cursor-pointer"
              >
                <div className="relative">
                  <div className="absolute -inset-2 border border-stone-300/40" />
                  <div className="relative overflow-hidden">
                    <img
                      src={image}
                      alt={`${property.title} - Image ${index + 1}`}
                      className="w-full h-[300px] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98]"
                    />
                    <div className="absolute inset-0 bg-stone-900/10" />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          <PropertyMap city={city} />
        </motion.div>

        <SimilarProperties
          currentLocation={property.location}
          currentTitle={property.title}
        />
      </div>

      <ImageLightbox
        isOpen={isLightboxOpen}
        onClose={() => setIsLightboxOpen(false)}
        images={allImages}
        currentImageIndex={currentImageIndex}
        onPrevious={handlePreviousImage}
        onNext={handleNextImage}
        onThumbnailClick={setCurrentImageIndex}
      />
    </div>
  );
};

export default PropertyDetail;
