import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const MentionsLegales = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.hash === "#fees") {
      const element = document.getElementById("fees");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div className="w-full bg-[#f8f5f2] min-h-screen py-24">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-12"
        >
          <div className="flex items-center gap-4">
            <Link
              to="/"
              className="text-stone-500 font-serif text-sm tracking-wider hover:text-stone-800 transition-colors flex items-center gap-2"
            >
              <span className="w-12 h-[1px] bg-stone-400" />
              <span>{t("backToHome")}</span>
            </Link>
          </div>

          <h1 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-stone-800 leading-[1.1]">
            {t("legalNotice.title")}
          </h1>

          <div className="prose prose-stone max-w-none font-serif">
            <p>
              {t("legalNotice.lastUpdate")} : {new Date().toLocaleDateString()}
            </p>

            <h2>{t("legalNotice.publisher")}</h2>
            <p>
              {t("legalNotice.companyInfo.name")}
              <br />
              {t("legalNotice.companyInfo.type")}
              <br />
              {t("legalNotice.companyInfo.address")}
              <br />
              {t("legalNotice.companyInfo.siret")}
              <br />
              {t("legalNotice.companyInfo.license")}
            </p>

            <h2>{t("legalNotice.director")}</h2>
            <p>{t("legalNotice.directorName")}</p>

            <h2>{t("legalNotice.hosting")}</h2>
            <p>{t("legalNotice.hostingProvider")}</p>

            <h2>{t("legalNotice.contact")}</h2>
            <p>
              {t("footer.phone")}
              <br />
              {t("footer.email")}
            </p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              id="fees"
              className="pt-12"
            >
              <h2>{t("fees.title")}</h2>
              <p>{t("fees.description")}</p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default MentionsLegales;
