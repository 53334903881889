import i18n from "i18next";
import frTranslation from "./locales/fr.json";
import enTranslation from "./locales/en.json";
import ruTranslation from "./locales/ru.json";
import zhTranslation from "./locales/zh.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      fr: { translation: frTranslation },
      en: { translation: enTranslation },
      ru: { translation: ruTranslation },
      zh: { translation: zhTranslation },
    },
    ns: ["translation"],
    supportedLngs: ["fr", "en", "ru", "zh"],
    fallbackLng: "fr",
    react: {
      useSuspense: false,
    },
    missingKeyHandler: (_lngs, _ns, key, _fallbackValue) => {
      throw new Error(`Translation key "${key}" is missing`);
    },
    saveMissing: true,
  });

export default i18n;
