import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  // Using one of the actual properties from the collection
  const featuredProperty = {
    title: "T3 rénové au Lac",
    location: "Saint-Julien-du-Verdon, France",
    price: "280 000€",
    image:
      "https://createsiteai.b-cdn.net/c/_/aca46be7-05bc-4558-9431-bd2b143d317e",
  };

  return (
    <div className="relative w-full desktop:h-screen laptop:h-screen tablet:h-auto mobile:h-auto overflow-hidden bg-[#f8f5f2]">
      <div
        className="absolute inset-0 opacity-[0.03]"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      />

      <div className="relative h-full max-w-[1800px] mx-auto grid grid-rows-[auto_1fr]">
        <motion.header
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="w-full desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6 py-8"
        >
          <nav className="flex justify-between items-center">
            <Link
              to="/"
              className="flex items-center gap-6 mobile:justify-center mobile:w-full"
            >
              <div className="w-60 h-36 desktop:-ml-8 laptop:-ml-8 tablet:mx-auto mobile:ml-0 flex items-center justify-center">
                <img
                  src="https://createsiteai.b-cdn.net/c/_/26118883-6fac-4c88-94b0-0cd6df1d4d64"
                  alt="Elegance Logo"
                  className="w-36 h-full object-contain brightness-0 transform scale-[2]"
                />
              </div>
              <div className="font-serif desktop:text-base laptop:text-base tablet:text-sm mobile:hidden">
                <p className="text-stone-800 tracking-[0.2em]"></p>
                <p className="text-stone-500 text-sm tracking-wider"></p>
              </div>
            </Link>
            <div className="desktop:flex laptop:flex tablet:hidden mobile:hidden gap-16 text-stone-600 font-serif text-sm tracking-wider">
              <Link
                to="/contact"
                className="hover:text-stone-900 transition-colors scale-150 origin-left"
              >
                {t("hero.contact")}
              </Link>
            </div>
          </nav>
        </motion.header>

        <div className="px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6 flex items-center">
          <div className="w-full flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col desktop:gap-12 laptop:gap-8 tablet:gap-16 mobile:gap-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="desktop:w-[45%] laptop:w-[45%] tablet:w-full mobile:w-full"
            >
              <div className="space-y-8">
                <div className="inline-block">
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-[1px] bg-stone-400" />
                    <span className="text-stone-500 font-serif text-sm tracking-[0.3em]">
                      {t("hero.tagline")}
                    </span>
                  </div>
                </div>

                <h1 className="font-serif desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl text-stone-800 leading-[1.1]">
                  {t("hero.title.line1")}
                  <br />
                  <span className="italic text-stone-600">
                    {t("hero.title.line2")}
                  </span>
                  <br />
                  {t("hero.title.line3")}
                </h1>

                <p className="font-serif text-stone-600 desktop:text-lg laptop:text-lg tablet:text-base mobile:text-base leading-relaxed max-w-xl">
                  {t("hero.description")}
                </p>

                <div className="flex gap-6 pt-4">
                  <Link
                    to="/collection"
                    className="group relative px-12 py-5 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden"
                  >
                    <span className="relative z-10">{t("hero.cta")}</span>
                    <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
                  </Link>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="desktop:w-[55%] laptop:w-[55%] tablet:w-full mobile:w-full relative"
            >
              <Link
                to={`/property/${encodeURIComponent(featuredProperty.title)}`}
                className="block"
              >
                <div className="relative">
                  <div className="absolute -inset-4 border border-stone-300/40" />
                  <div className="relative overflow-hidden">
                    <img
                      src={featuredProperty.image}
                      alt="Featured Property"
                      className="w-full desktop:h-[600px] laptop:h-[500px] tablet:h-[400px] mobile:h-[300px] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98]"
                    />
                    <div className="absolute inset-0 bg-stone-900/10" />

                    <div className="absolute bottom-0 left-0 right-0 p-8 bg-gradient-to-t from-stone-900/80 to-transparent">
                      <div className="text-stone-100 font-serif">
                        <p className="text-sm tracking-[0.2em] mb-2">
                          {t("hero.featuredProperty.label")}
                        </p>
                        <h3 className="text-2xl mb-2">
                          {t("property.properties.t3Title")}
                        </h3>
                        <p className="text-stone-300 text-sm tracking-wider">
                          {featuredProperty.location}
                        </p>
                        <p className="text-stone-100 text-sm tracking-wider mt-2">
                          {featuredProperty.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
