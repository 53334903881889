import { motion } from "framer-motion";

interface PropertyMapProps {
  city: string;
}

const PropertyMap = ({ city }: PropertyMapProps) => {
  // Using an iframe to embed Google Maps
  const encodedCity = encodeURIComponent(city);
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=${encodedCity},France&zoom=13`;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="w-full"
    >
      <div className="relative">
        <div className="absolute -inset-4 border border-stone-300/40" />
        <div className="relative">
          <iframe
            title="Property Location"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            src={mapUrl}
            className="grayscale-[0.7] contrast-[1.05] brightness-[0.95]"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default PropertyMap;
