interface PropertyImages {
  [key: string]: {
    mainImage: string;
    detailImages: string[];
  };
}

export const propertyImages: PropertyImages = {
  "T3 rénové au Lac": {
    mainImage:
      "https://createsiteai.b-cdn.net/c/_/aca46be7-05bc-4558-9431-bd2b143d317e",
    detailImages: [
      "https://createsiteai.b-cdn.net/c/_/7827c553-8eb0-4428-8bc9-1f031ed6633a",
      "https://createsiteai.b-cdn.net/c/_/5956f5d3-f819-4d68-9ad7-15d7157bb7f7",
      "https://createsiteai.b-cdn.net/c/_/f6940a07-de09-4163-a99a-7bb002e4d3b0",
      "https://createsiteai.b-cdn.net/c/_/c95ff064-4c6c-4b72-b4bf-61d80d9312c8",
      "https://createsiteai.b-cdn.net/c/_/86fde8dc-03ee-4340-8b7c-238a5394f022",
      "https://createsiteai.b-cdn.net/c/_/f7016807-d8e6-469e-96ae-32b495fbaae2",
      "https://createsiteai.b-cdn.net/c/_/93ec794b-0d52-44d7-8362-6e8833a102fc",
      "https://createsiteai.b-cdn.net/c/_/2e9c546c-e530-4314-874e-aba5a1df630f",
      "https://createsiteai.b-cdn.net/c/_/6227112c-71a3-42e3-ac82-f3a46f481e5b",
      "https://createsiteai.b-cdn.net/c/_/e4ef2ec3-9698-4f5d-892e-cc92dbb6d5d0",
      "https://createsiteai.b-cdn.net/c/_/4726ee0a-3ad9-4d1a-ba19-f477b7ef9a77",
      "https://createsiteai.b-cdn.net/c/_/6ee0cd00-e9c4-4c80-b16e-ab175410d7bc",
      "https://createsiteai.b-cdn.net/c/_/93c31e13-5a5d-46de-8095-8bf3c1a93e63",
      "https://createsiteai.b-cdn.net/c/_/abba1ea3-e17c-4754-a3c8-564b61bfe021",
      "https://createsiteai.b-cdn.net/c/_/bf11d0cb-d5ee-4078-a4b0-db680d02b69f",
      "https://createsiteai.b-cdn.net/c/_/60ff0788-a8f2-4dc5-9bca-b6918f1f6f1c",
    ],
  },
  "Appartement neuf avec jardin": {
    mainImage:
      "https://createsiteai.b-cdn.net/c/_/a32fb517-80be-4edd-8cd7-c8e24f9e18d9",
    detailImages: [
      "https://createsiteai.b-cdn.net/c/_/b8c6fd0b-9cf0-4314-ac13-b70f47cfbfe2",
      "https://createsiteai.b-cdn.net/c/_/21cb3057-dc5f-4aba-b1dc-9c806c8835ba",
      "https://createsiteai.b-cdn.net/c/_/84ea92e1-44c4-47b6-a94e-21c6fbe45a3c",
    ],
  },
};
