import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Listings from "../components/Listings";
import Team from "../components/Team";
import Footer from "../components/Footer";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <About />
      <Listings />
      <Team />
      <Footer />
    </>
  );
};

export default Home;
