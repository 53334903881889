import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FiFilter, FiX } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import DualRangeSlider from "../components/DualRangeSlider";

const ListingCard = ({
  image,
  title,
  location,
  price,
}: {
  image: string;
  title: string;
  location: string;
  price: string;
}) => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="group cursor-pointer"
    >
      <Link to={`/property/${encodeURIComponent(title)}`} className="block">
        <div className="relative">
          <div className="absolute -inset-2 border border-stone-300/40" />
          <div className="relative overflow-hidden">
            <img
              src={image}
              alt={title}
              className="w-full h-[400px] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98] transition-all duration-500 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-stone-900/10" />
            <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-stone-900/80 to-transparent">
              <div className="text-stone-100 font-serif">
                <h3 className="text-xl mb-2">
                  {title === "T3 rénové au Lac"
                    ? t("property.properties.t3Title")
                    : title === "Appartement neuf avec jardin"
                      ? t("property.properties.newApartmentWithGarden")
                      : title}
                </h3>
                <p className="text-stone-300 text-sm tracking-wider">
                  {location}
                </p>
                <p className="text-stone-100 text-sm tracking-wider mt-2">
                  {price}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

const FilterSidebar = ({
  isOpen,
  onClose,
  filters,
  setFilters,
  resetFilters,
}: {
  isOpen: boolean;
  onClose: () => void;
  filters: {
    minPrice: number;
    maxPrice: number;
    minSurface: number;
    maxSurface: number;
    location: string;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      minPrice: number;
      maxPrice: number;
      minSurface: number;
      maxSurface: number;
      location: string;
    }>
  >;
  resetFilters: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-100%" }}
          transition={{ type: "spring", damping: 20 }}
          className="fixed left-0 top-0 h-full w-80 bg-[#f8f5f2] border-r border-stone-200 z-50 p-6 overflow-y-auto"
        >
          <div className="space-y-8">
            <div className="flex justify-between items-center">
              <h3 className="font-serif text-xl text-stone-800">
                {t("collection.filters")}
              </h3>
              <button
                onClick={onClose}
                className="text-stone-500 hover:text-stone-800 transition-colors"
              >
                <FiX size={24} />
              </button>
            </div>

            <div className="space-y-6">
              <div className="space-y-4">
                <label className="block font-serif text-stone-800">
                  {t("collection.price")}
                </label>
                <DualRangeSlider
                  minValue={0}
                  maxValue={30000000}
                  step={100000}
                  currentMin={filters.minPrice}
                  currentMax={filters.maxPrice}
                  formatValue={(value) => `${value.toLocaleString()}€`}
                  onChange={(min, max) =>
                    setFilters((prev) => ({
                      ...prev,
                      minPrice: min,
                      maxPrice: max,
                    }))
                  }
                />
              </div>

              <div className="space-y-4">
                <label className="block font-serif text-stone-800">
                  {t("collection.surface")}
                </label>
                <DualRangeSlider
                  minValue={0}
                  maxValue={1000}
                  step={10}
                  currentMin={filters.minSurface}
                  currentMax={filters.maxSurface}
                  formatValue={(value) => `${value}m²`}
                  onChange={(min, max) =>
                    setFilters((prev) => ({
                      ...prev,
                      minSurface: min,
                      maxSurface: max,
                    }))
                  }
                />
              </div>

              <div className="space-y-4">
                <label className="block font-serif text-stone-800">
                  {t("collection.location")}
                </label>
                <input
                  type="text"
                  value={filters.location}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      location: e.target.value,
                    }))
                  }
                  placeholder={t("collection.searchPlaceholder")}
                  className="w-full px-4 py-2 border border-stone-300 focus:outline-none focus:border-stone-500 font-serif"
                />
              </div>

              <button
                onClick={resetFilters}
                className="w-full group relative px-6 py-3 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden"
              >
                <span className="relative z-10">
                  {t("collection.resetFilters")}
                </span>
                <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Collection = () => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const initialFilters = {
    minPrice: 0,
    maxPrice: 30000000,
    minSurface: 0,
    maxSurface: 1000,
    location: "",
  };
  const [filters, setFilters] = useState(initialFilters);

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const properties = [
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/a32fb517-80be-4edd-8cd7-c8e24f9e18d9",
      title: "Appartement neuf avec jardin",
      location: "Forcalquier, France",
      price: "430 000€",
      surface: 108,
    },
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/270bb3a5-81b7-4099-826f-6f6e90bd47fe",
      title: "T3 rénové au Lac",
      location: "Saint-Julien-du-Verdon, France",
      price: "280 000€",
      surface: 85,
    },
  ];

  const filteredProperties = properties.filter((property) => {
    const propertyPrice = parseInt(property.price.replace(/[^0-9]/g, ""));
    const propertyLocation = property.location.toLowerCase();
    const searchLocation = filters.location.toLowerCase();

    return (
      propertyPrice >= filters.minPrice &&
      propertyPrice <= filters.maxPrice &&
      property.surface >= filters.minSurface &&
      property.surface <= filters.maxSurface &&
      (filters.location === "" || propertyLocation.includes(searchLocation))
    );
  });

  return (
    <div className="w-full bg-[#f8f5f2] min-h-screen py-24">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <div className="flex items-center justify-between mb-8">
            <Link
              to="/"
              className="text-stone-500 font-serif text-sm tracking-wider hover:text-stone-800 transition-colors flex items-center gap-2"
            >
              <span className="w-12 h-[1px] bg-stone-400" />
              <span>{t("collection.backToHome")}</span>
            </Link>
            <button
              onClick={() => setIsFilterOpen(true)}
              className="flex items-center gap-2 text-stone-500 hover:text-stone-800 transition-colors"
            >
              <FiFilter size={20} />
              <span className="font-serif text-sm tracking-wider">
                {t("collection.filters")}
              </span>
            </button>
          </div>
          <h1 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-stone-800 leading-[1.1] mb-8">
            {t("collection.title")}
          </h1>
        </motion.div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
          {filteredProperties.map((property, index) => (
            <ListingCard key={index} {...property} />
          ))}
        </div>

        <FilterSidebar
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          filters={filters}
          setFilters={setFilters}
          resetFilters={resetFilters}
        />
      </div>
    </div>
  );
};

export default Collection;
