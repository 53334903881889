import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import TeamMember from "./TeamMember";

const Team = () => {
  const { t } = useTranslation();
  const team = [
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/b97fd49c-4aa1-4ce9-b3d4-736e070e6bca",
      name: "Adrien Brochier Allari",
      role: "CEO & Founder",
    },
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/1cb26fbd-fd62-4874-b172-ca5cdaaf735a",
      name: "Jean-Luc Brochier",
      role: "Senior Advisor",
    },
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/f4c124b0-e503-4ff4-a5dc-5fda985260bf",
      name: "Brigitte Garnier",
      role: "Real Estate Broker",
    },
    {
      image:
        "https://createsiteai.b-cdn.net/c/_/cad3d179-3f69-4824-a5ec-103797e7ffce",
      name: "Mystie Miretti",
      role: "Founders' Associate",
    },
  ];

  return (
    <div className="w-full bg-[#f8f5f2] py-24">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <div className="inline-block">
            <div className="flex items-center gap-4">
              <div className="w-12 h-[1px] bg-stone-400" />
              <span className="text-stone-500 font-serif text-sm tracking-[0.3em]">
                {t("team.tagline")}
              </span>
            </div>
          </div>
          <h2 className="font-serif desktop:text-4xl laptop:text-3xl tablet:text-3xl mobile:text-2xl text-stone-800 leading-[1.2] mt-8">
            {t("team.title")}
          </h2>
        </motion.div>

        <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
          {team.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
