import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useFormspark } from "@formspark/use-formspark";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";

const SuccessPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-stone-900/50 flex items-center justify-center z-50 px-4"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-[#f8f5f2] p-8 max-w-md w-full relative"
          >
            <div className="space-y-6">
              <p className="font-serif text-stone-800 leading-relaxed">
                {t("newsletter.success")}
                <br />
                <br />
                Sincèrement,
              </p>
              <button
                onClick={onClose}
                className="w-full group relative px-6 py-3 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden"
              >
                <span className="relative z-10">{t("newsletter.close")}</span>
                <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Footer = () => {
  const [submit] = useFormspark({
    formId: "A4kBJRGJY",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="w-full bg-[#f8f5f2] py-24 border-t border-stone-200">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-6"
          >
            <h3 className="font-serif text-xl text-stone-800">
              {t("newsletter.title")}
            </h3>
            <p className="text-stone-600 font-serif text-sm">
              {t("newsletter.description")}
            </p>
            <form
              className="space-y-4"
              onSubmit={async (e) => {
                e.preventDefault();
                const emailInput = e.currentTarget.querySelector(
                  'input[type="email"]',
                ) as HTMLInputElement;
                const email = emailInput.value;
                if (email) {
                  await submit({ email });
                  emailInput.value = "";
                  setShowSuccess(true);
                }
              }}
            >
              <input
                type="email"
                placeholder={t("newsletter.placeholder")}
                required
                className="w-full px-4 py-3 border border-stone-300 bg-white focus:outline-none focus:border-stone-500 font-serif"
              />
              <button
                type="submit"
                className="w-full group relative px-6 py-3 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden"
              >
                <span className="relative z-10">{t("newsletter.button")}</span>
                <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
              </button>
            </form>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="space-y-6"
          >
            <h3 className="font-serif text-xl text-stone-800">
              {t("footer.contact")}
            </h3>
            <div className="space-y-3 text-stone-600 font-serif text-sm">
              <p>{t("footer.phone")}</p>
              <p>{t("footer.email")}</p> <p>{t("footer.address")}</p>
              <p>{t("footer.license")}</p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="space-y-6"
          >
            <h3 className="font-serif text-xl text-stone-800">
              {t("footer.legal.title")}
            </h3>
            <div className="space-y-3">
              <Link
                to="/cgu"
                className="block text-stone-600 font-serif text-sm hover:text-stone-900 transition-colors"
              >
                {t("footer.legal.terms")}
              </Link>
              <Link
                to="/mentions-legales"
                className="block text-stone-600 font-serif text-sm hover:text-stone-900 transition-colors"
              >
                {t("footer.legal.legalNotice")}
              </Link>
              <Link
                to="/politique-confidentialite"
                className="block text-stone-600 font-serif text-sm hover:text-stone-900 transition-colors"
              >
                {t("footer.legal.privacy")}
              </Link>
              <Link
                to="/mentions-legales#fees"
                className="block text-stone-600 font-serif text-sm hover:text-stone-900 transition-colors"
              >
                {t("footer.legal.fees")}
              </Link>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="space-y-6"
          >
            <h3 className="font-serif text-xl text-stone-800">
              {t("footer.followUs")}
            </h3>
            <div className="flex gap-4">
              <a
                href="https://www.facebook.com/vivaldyOfficial"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 flex items-center justify-center border border-stone-300 text-stone-600 hover:bg-stone-900 hover:text-stone-100 hover:border-stone-900 transition-all duration-300"
              >
                <FaFacebookF size={16} />
              </a>
              <a
                href="https://www.instagram.com/vivaldyofficiel/#"
                className="w-10 h-10 flex items-center justify-center border border-stone-300 text-stone-600 hover:bg-stone-900 hover:text-stone-100 hover:border-stone-900 transition-all duration-300"
              >
                <FaInstagram size={16} />
              </a>
              <a
                href="https://www.linkedin.com/company/vivaldy"
                className="w-10 h-10 flex items-center justify-center border border-stone-300 text-stone-600 hover:bg-stone-900 hover:text-stone-100 hover:border-stone-900 transition-all duration-300"
              >
                <FaLinkedinIn size={16} />
              </a>
              <a
                href="https://www.tiktok.com/@vivaldy_official"
                className="w-10 h-10 flex items-center justify-center border border-stone-300 text-stone-600 hover:bg-stone-900 hover:text-stone-100 hover:border-stone-900 transition-all duration-300"
              >
                <FaTiktok size={16} />
              </a>
            </div>
          </motion.div>
        </div>
      </div>
      <SuccessPopup
        isOpen={showSuccess}
        onClose={() => setShowSuccess(false)}
      />
    </div>
  );
};

export default Footer;
