import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const CGU = () => {
  return (
    <div className="w-full bg-[#f8f5f2] min-h-screen py-24">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-12"
        >
          <div className="flex items-center gap-4">
            <Link
              to="/"
              className="text-stone-500 font-serif text-sm tracking-wider hover:text-stone-800 transition-colors flex items-center gap-2"
            >
              <span className="w-12 h-[1px] bg-stone-400" />
              <span>RETOUR À L'ACCUEIL</span>
            </Link>
          </div>

          <h1 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-stone-800 leading-[1.1]">
            Conditions Générales d'Utilisation
          </h1>

          <div className="prose prose-stone max-w-none font-serif">
            <p>
              Date de dernière mise à jour : {new Date().toLocaleDateString()}
            </p>

            <h2>1. Acceptation des conditions</h2>
            <p>
              En accédant et en utilisant ce site, vous acceptez d'être lié par
              ces conditions d'utilisation.
            </p>

            <h2>2. Services proposés</h2>
            <p>
              Notre site propose des services de présentation et de mise en
              relation pour des biens immobiliers.
            </p>

            <h2>3. Propriété intellectuelle</h2>
            <p>
              L'ensemble du contenu de ce site est protégé par le droit d'auteur
              et autres droits de propriété intellectuelle.
            </p>

            <h2>4. Responsabilité</h2>
            <p>
              Nous nous efforçons de maintenir les informations de ce site à
              jour, mais ne pouvons garantir leur exactitude absolue.
            </p>

            <h2>5. Modification des conditions</h2>
            <p>
              Nous nous réservons le droit de modifier ces conditions à tout
              moment. Les modifications prennent effet dès leur publication.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CGU;
