import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="w-full bg-[#f8f5f2] min-h-screen">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6 py-24">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-16"
        >
          <div className="flex items-center gap-4 mb-8">
            <Link
              to="/"
              className="text-stone-500 font-serif text-sm tracking-wider hover:text-stone-800 transition-colors flex items-center gap-2"
            >
              <span className="w-12 h-[1px] bg-stone-400" />
              <span>RETOUR À L'ACCUEIL</span>
            </Link>
          </div>
          <h1 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-stone-800 leading-[1.1] mb-8">
            Nous Contacter
          </h1>
        </motion.div>

        <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
          >
            <form className="space-y-6">
              <div>
                <label className="block font-serif text-stone-800 mb-2">
                  Name
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-3 border border-stone-300 bg-white focus:outline-none focus:border-stone-500 font-serif"
                  placeholder="Votre nom"
                />
              </div>
              <div>
                <label className="block font-serif text-stone-800 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full px-4 py-3 border border-stone-300 bg-white focus:outline-none focus:border-stone-500 font-serif"
                  placeholder="Votre email"
                />
              </div>
              <div>
                <label className="block font-serif text-stone-800 mb-2">
                  Message
                </label>
                <textarea
                  className="w-full px-4 py-3 border border-stone-300 bg-white focus:outline-none focus:border-stone-500 font-serif h-32"
                  placeholder="Votre message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="group relative px-12 py-5 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden"
              >
                <span className="relative z-10">ENVOYER LE MESSAGE</span>
                <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
              </button>
            </form>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
          >
            <div className="relative">
              <div className="absolute -inset-4 border border-stone-300/40" />
              <div className="relative overflow-hidden">
                <img
                  src="https://createsiteai.b-cdn.net/c/_/af499c37-16f1-481e-8ba9-26b3fedf77eb"
                  alt="Office Interior"
                  className="w-full h-[600px] object-cover grayscale contrast-[1.1] brightness-[0.9]"
                />
                <div className="absolute inset-0 bg-stone-900/20" />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
