import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiChevronDown, FiCheck } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const DPEDropdown = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const isNewApartment =
    decodeURIComponent(id || "") === "Appartement neuf avec jardin";

  const energyGrades = [
    { grade: "A", color: "bg-[#2D9A44]", textColor: "text-white" },
    { grade: "B", color: "bg-[#51B84D]", textColor: "text-white" },
    { grade: "C", color: "bg-[#A6CE50]", textColor: "text-stone-800" },
    { grade: "D", color: "bg-[#F2E42C]", textColor: "text-stone-800" },
    { grade: "E", color: "bg-[#F09435]", textColor: "text-white" },
    { grade: "F", color: "bg-[#E63427]", textColor: "text-white" },
    { grade: "G", color: "bg-[#E1131D]", textColor: "text-white" },
  ];

  const climateGrades = [
    { grade: "A", color: "bg-[#DDB4DD]", textColor: "text-stone-800" },
    { grade: "B", color: "bg-[#B181B1]", textColor: "text-white" },
    { grade: "C", color: "bg-[#8B4E8B]", textColor: "text-white" },
    { grade: "D", color: "bg-[#652465]", textColor: "text-white" },
    { grade: "E", color: "bg-[#3F0E3F]", textColor: "text-white" },
    { grade: "F", color: "bg-[#1A021A]", textColor: "text-white" },
    { grade: "G", color: "bg-[#000000]", textColor: "text-white" },
  ];

  return (
    <div className="w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full group relative px-6 py-3 bg-stone-900 text-stone-100 font-serif text-sm tracking-widest overflow-hidden flex items-center justify-between"
      >
        <span className="relative z-10">
          {t("property.properties.energyPerformance")}
        </span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className="relative z-10"
        >
          <FiChevronDown size={20} />
        </motion.div>
        <div className="absolute inset-0 bg-stone-700 transform translate-y-full transition-transform duration-300 group-hover:translate-y-0" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="p-6 border border-stone-300 border-t-0 space-y-6 font-serif text-stone-600">
              <div className="space-y-2">
                <span className="block mb-4">Classe énergie</span>
                <div className="flex flex-col gap-1">
                  {energyGrades.map((grade) => (
                    <div
                      key={grade.grade}
                      className={`flex items-center ${
                        (isNewApartment && grade.grade === "A") ||
                        (!isNewApartment && grade.grade === "D")
                          ? "ring-2 ring-stone-800"
                          : ""
                      }`}
                    >
                      <div
                        className={`w-8 h-8 ${grade.color} ${grade.textColor} flex items-center justify-center font-bold`}
                      >
                        {grade.grade}
                      </div>
                      <div
                        className={`h-8 grow ${grade.color} ${grade.textColor} flex items-center px-4`}
                      >
                        {((isNewApartment && grade.grade === "A") ||
                          (!isNewApartment && grade.grade === "D")) && (
                          <FiCheck size={20} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="space-y-2">
                <span className="block mb-4">Classe climat</span>
                <div className="flex flex-col gap-1">
                  {climateGrades.map((grade) => (
                    <div
                      key={grade.grade}
                      className={`flex items-center ${
                        (isNewApartment && grade.grade === "A") ||
                        (!isNewApartment && grade.grade === "B")
                          ? "ring-2 ring-stone-800"
                          : ""
                      }`}
                    >
                      <div
                        className={`w-8 h-8 ${grade.color} ${grade.textColor} flex items-center justify-center font-bold`}
                      >
                        {grade.grade}
                      </div>
                      <div
                        className={`h-8 grow ${grade.color} ${grade.textColor} flex items-center px-4`}
                      >
                        {((isNewApartment && grade.grade === "A") ||
                          (!isNewApartment && grade.grade === "B")) && (
                          <FiCheck size={20} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DPEDropdown;
