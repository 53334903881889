import { motion } from "framer-motion";

interface TeamMemberProps {
  image: string;
  name: string;
  role: string;
}

const TeamMember = ({ image, name, role }: TeamMemberProps) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8 }}
    className="group"
  >
    <div className="relative">
      <div className="absolute -inset-2 border border-stone-300/40" />
      <div className="relative overflow-hidden">
        <img
          src={image}
          alt={name}
          className="w-full desktop:h-[400px] laptop:h-[350px] tablet:h-[300px] mobile:h-[250px] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98]"
        />
        <div className="absolute inset-0 bg-stone-900/10" />
        <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-stone-900/80 to-transparent">
          <div className="text-stone-100 font-serif">
            <h3 className="text-xl mb-2">{name}</h3>
            <p className="text-stone-300 text-sm tracking-wider">{role}</p>
          </div>
        </div>
      </div>
    </div>
  </motion.div>
);

export default TeamMember;
