import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-[#f8f5f2] desktop:py-24 laptop:py-24 tablet:py-16 mobile:py-12 laptop:py-24 tablet:py-20 mobile:py-12">
      <div className="max-w-[1800px] mx-auto px-4 desktop:px-16 laptop:px-12 tablet:px-8 mobile:px-6">
        <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col desktop:gap-12 laptop:gap-8 tablet:gap-16 mobile:gap-6">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
          >
            <div className="relative">
              <div className="absolute -inset-4 border border-stone-300/40" />
              <div className="relative overflow-hidden">
                <img
                  src="https://createsiteai.b-cdn.net/c/_/2bc103cc-1932-496c-a4c3-9d078faea386"
                  alt="Historic Building"
                  className="w-full desktop:h-[600px] laptop:h-[600px] tablet:h-[500px] mobile:h-[400px] object-cover grayscale-[0.3] contrast-[1.02] brightness-[0.98]"
                />
                <div className="absolute inset-0 bg-stone-900/10" />
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
          >
            <div className="space-y-8">
              <div className="inline-block">
                <div className="flex items-center gap-4">
                  <div className="w-12 h-[1px] bg-stone-400" />
                  <span className="text-stone-500 font-serif text-sm tracking-[0.3em]">
                    {t("about.tagline")}
                  </span>
                </div>
              </div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="space-y-6"
              >
                <h2 className="font-serif desktop:text-4xl laptop:text-3xl tablet:text-3xl mobile:text-2xl text-stone-800 leading-[1.2]">
                  {" "}
                  {t("about.title")}
                </h2>
                <p className="font-serif text-stone-600 leading-relaxed">
                  {t("about.description")}
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="space-y-6"
              >
                <div className="w-16 h-[1px] bg-stone-400" />
                <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
                  <div>
                    <h3 className="font-serif text-xl text-stone-800 mb-3">
                      {t("about.digital.title")}
                    </h3>
                    <p className="font-serif text-stone-600 text-sm leading-relaxed">
                      {t("about.digital.description")}
                    </p>
                  </div>
                  <div>
                    <h3 className="font-serif text-xl text-stone-800 mb-3">
                      {t("about.expertise.title")}
                    </h3>
                    <p className="font-serif text-stone-600 text-sm leading-relaxed">
                      {t("about.expertise.description")}
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;
