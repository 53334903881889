import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "fr", native: "Français", latin: "French" },
  { code: "en", native: "English", latin: "English" },
  { code: "ru", native: "Русский", latin: "Russian" },
  { code: "zh", native: "中文", latin: "Mandarin" },
];

const LanguageSelector = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { i18n } = useTranslation();

  const handleLanguageChange = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    setIsExpanded(false);
  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <div
        className="relative"
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
      >
        <motion.button
          className="w-12 h-12 rounded-full bg-stone-900 text-stone-100 flex items-center justify-center font-serif text-sm hover:bg-stone-700 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {i18n.language.toUpperCase()}
        </motion.button>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute bottom-full right-0 mb-2 min-w-[160px]"
            >
              <div className="bg-stone-900 rounded-lg overflow-hidden">
                {languages.map((lang, index) => (
                  <motion.button
                    key={lang.code}
                    className="w-full px-4 py-3 text-left text-stone-100 hover:bg-stone-700 transition-colors font-serif"
                    onClick={() => handleLanguageChange(lang.code)}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: { delay: index * 0.1 },
                    }}
                  >
                    <div className="flex flex-col">
                      <span className="text-sm">{lang.native}</span>
                      <span className="text-xs text-stone-400">
                        {lang.latin}
                      </span>
                    </div>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default LanguageSelector;
